export const promiseMoment = (callback) => {
	if (window.moment === undefined) {
		require.ensure(['moment', 'moment/locale/cs'], require => {
			window.moment = require('moment');
			require('moment/locale/cs');
			window.moment.locale('cs');
			callback(window.moment);
		});
	} else {
		callback(window.moment);
	}
};
