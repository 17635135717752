import './../styles/app.scss';

import 'nette.ajax.js';

import { Tooltip, Modal, Dropdown } from "bootstrap";
import { flashMessage } from "./modules/flashMessage.js";
import { loadFromSource } from "./modules/loadFromSource.js";

// LazyLoad
import LazyLoad from "vanilla-lazyload";
const LazyLoadInstance = new LazyLoad();

// Sentry
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import SentryRRWeb from "@sentry/rrweb";
import BuildInfo from "!webpack-plugin-buildinfo?gitHash!";

Sentry.init({
	dsn: "https://e47c9bc9bbc14023b1fae6445d1a79b1@o1102463.ingest.sentry.io/6237229",
	integrations: [
		new Integrations.BrowserTracing(),
		new SentryRRWeb(),
	],
	ignoreErrors:[
		"Non-Error exception captured",
		"Non-Error promise rejection captured"
	],
	release: BuildInfo.gitHash,
	tracesSampleRate: 1.0,
});

if (Object.prototype.hasOwnProperty.call(window, 'sentrySettings')) {
	Sentry.setUser(window.sentrySettings.user);

	if (window.sentrySettings.eventId) {
		Sentry.showReportDialog({
			eventId: window.sentrySettings.eventId,
		});
	}
}

// Event pop state
let previousLocation = window.location;
window.addEventListener('popstate', e => {
	let location = e.target.location;

	if (location.pathname !== previousLocation.pathname) {
		$.nette.ajax(location.href);
	}
	previousLocation = location;
});

// Flash messages
window.flashMessage = flashMessage;

// Running every load page and ajax complete
const everyLoadInit = () => {
	// Nette forms
	if (document.getElementsByTagName('form').length > 0) {
		require.ensure('nette-forms', require => {
			require('nette-forms');
		});
	}

	// Tooltip
	[].slice.call(document.querySelectorAll('[role="tooltip"]')).map(el => el.remove());
	let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	tooltipTriggerList.map(el => new Tooltip(el));

	// Dropdown
	let dropdownElementList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'));
	dropdownElementList.map(el => new Dropdown(el));

	// Select2
	$('select[name=\'per_page\'], select[name^=\'group_action\']').addClass('no-select2');
	if ($('select:not(.no-select2)').length > 0) {
		require.ensure('select2', require => {
			require('select2');
			$.fn.select2.defaults.set('theme', 'bootstrap-5');
			$('select:not(.no-select2)').select2({width: '100%'});
			$('.select2-selection').addClass('form-control input-sm form-control-sm');
		});
	}

	// Datetime Picker
	if ($('[data-onload-datetimepicker]:not(.datetimepicker-input),[data-provide="datepicker"]:not(.datetimepicker-input)').length > 0) {
		require.ensure(['./modules/datetimepicker'], require => {
			require('./modules/datetimepicker').init();
		});
	}

	// LazyLoad
	LazyLoadInstance.update();

	// Menu control
	const menuControl = {
		open: type => {
			$(`.collapse-menu[data-id="${type}"]`).css('display', 'block');
			setTimeout(() => {$(`.collapse-menu[data-id="${type}"]`).addClass('opened');}, 50);
		},
		close: type => {
			$(`.collapse-menu[data-id="${type}"]`).removeClass('opened');
			setTimeout(() => {$(`.collapse-menu[data-id="${type}"]`).css('display', 'none');}, 500);
		}
	};
	$('[data-toggle-menu]').off('click').on('click', e => {
		menuControl.open($(e.currentTarget).data('toggle-menu'));
	});
	$('.collapse-menu .btn-close,.collapse-menu [data-refresh-url]').off('click').on('click', e => {
		e.preventDefault();
		menuControl.close($(e.currentTarget).closest('.collapse-menu').data('id'));
	});

	// Others
	$('[data-toggle="load-from-source"]').off('change').on('change', e => loadFromSource(e));
};

const spinner = $("#global-spinner");
const modals = {};

$.nette.ext('main', {
	start: () => {
		// Spinner
		if (spinner) {
			setTimeout(() => spinner.css('opacity', 0).css('display', 'flex').animate({opacity: 1}, 150), 100);
		}
	},
	complete: (response, type, settings) => {
		everyLoadInit();

		// Uri refresh
		if (settings.nette !== undefined && settings.nette.ui.hasAttribute('data-refresh-url')) {
			let title = response.snippets['snippet--title'] === undefined ? '' : response.snippets['snippet--title'].trim();
			history.pushState(history.state, title, settings.url);
		}

		// Modals
		if (Object.prototype.hasOwnProperty.call(response, 'modals')) {
			for (let i in response.modals) {
				if (Object.prototype.hasOwnProperty.call(response.modals, i)) {
					let htmlId = '#modal-' + response.modals[i][0];
					let modal;
					if (modals[htmlId]) {
						modal = modals[htmlId];
					} else {
						modal = new Modal(htmlId);
						modals[htmlId] = modal;
					}
					if (response.modals[i][1] === 'show') {
						modal._element.addEventListener('hidden.bs.modal', () => {
							modal._element.remove();
							delete modals[htmlId];
						});
						modal.show();
						$(modal._element).before($('.modal-backdrop').last());
					} else {
						modal.hide();
					}
				}
			}
		}

		// Spinner
		if (spinner) {
			spinner.stop().animate({opacity: 0}, 150);
			setTimeout(() => spinner.css('display', 'none'), 150);
		}
	},
	before: (xhr, settings) => {
		let confirm_message;
		if (settings.nette) {
			confirm_message = settings.nette.el.data('confirm');
			if (!confirm_message) {
				confirm_message = settings.nette.el.data('datagrid-confirm');
			}
			if (confirm_message) {
				require.ensure(['./dialogs'], require => {
					let Dialogs = require('./dialogs');
					Dialogs.DeletionDialog.fire(confirm_message).then(r => {
						if (r.isConfirmed) {
							$.nette.ajax(settings.url);
						}
					});
				});
				return false;
			}
		}
		return true;
	}
});
everyLoadInit();

$.nette.init();
window.$ = $;
$(document).trigger('appLoaded');
