import {TempusDominus, DateTime, DefaultOptions} from '@eonasdan/tempus-dominus';
import moment from 'moment';

DefaultOptions.display.components.useTwentyfourHour = true;
DefaultOptions.allowInputToggle = true;
DefaultOptions.hooks.inputFormat = (context, date) => {
	return moment(date).format(context._input.getAttribute('data-date-format') || 'YYYY-MM-DD HH:mm:ss');
};

export const init = () => {
	$('[data-onload-datetimepicker]:not(.datetimepicker-input)').each((i, e) => {
		let el = $(e),
			opt = el.data('onload-datetimepicker');
		if (!(opt instanceof Object)) {
			opt = JSON.parse(opt);
		}

		if (el.val() !== '') {
			opt.defaultDate = new DateTime(el.val());
		}

		if (Object.prototype.hasOwnProperty.call(opt, 'inline') && opt.inline === true) {
			el.hide();
		}

		el.attr('data-toggle', 'datetimepicker').attr('data-target', '#' + el.attr('id'));
		try {
			new TempusDominus(document.getElementById(el.attr('id')), opt);
			el.addClass('datetimepicker-input');
		}
		catch(err) {
			console.log(err.message);
		}
	});
	$('[data-provide="datepicker"]:not(.datetimepicker-input)').each((i, e) => {
		let el = $(e),
			opt = {};

		if (el.val() !== '') {
			opt.defaultDate = new DateTime(el.val());
		}

		$([el, el.next('.input-group-append')]).each((i, e) => {
			e.attr('data-toggle', 'datetimepicker').attr('data-target', '#' + el.attr('id'));
		});

		try {
			new TempusDominus(e, opt);
			el.addClass('datetimepicker-input');
		}
		catch(err) {
			console.log(err.message);
		}
	});
};
