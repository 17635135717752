const loadFromSource = event => {
	let el = $(event.currentTarget);
	if (el.val() === '') {
		el.removeClass('is-invalid');
		return;
	}
	$.nette.ajax({
		method: 'POST',
		url: '?do=checkSource',
		data: {
			schema: el.data('schema'),
			url: el.val()
		},
		success: (response) => {
			if (Object.prototype.hasOwnProperty.call(response, 'error')) {
				el.addClass('is-invalid');
				alert(response.error);
			} else {
				require('./moment').promiseMoment(moment => {
					el.off('change').removeAttr('data-toggle').removeClass('is-invalid');

					el.find('option').remove();
					el.append(new Option('-- pro spojení vyberte položku ze seznamu --', ''));
					for (let i in response) {
						if (Object.prototype.hasOwnProperty.call(response, i)) {
							let name = response[i].name;

							if (Object.prototype.hasOwnProperty.call(response[i], 'since')) {
								name = moment(response[i].since).format('llll') + ' – ' + name;
							} else if (Object.prototype.hasOwnProperty.call(response[i], 'date')) {
								name = moment(response[i].date).format('llll') + ' – ' + name;
							}
							el.append(new Option(name, i));
						}
					}

					el.focus().off('select2:select').on('select2:select', () => {
						let value = el.val();
						let form = el.closest('form');
						form.find('input[name="' + el.attr('name').substr(1) + '"]').val(value);

						// Reset
						form.find('[data-onload-datetimepicker]').each((i, e) => {
							try {
								$(e).datetimepicker('clear');
							}
							catch(err) {
								console.log(err.message);
							}
						});
						form[0].reset();

						// Fill selected
						if (Object.prototype.hasOwnProperty.call(response, value)) {
							let item = response[value];
							for (let i in item) {
								if (Object.prototype.hasOwnProperty.call(item, i) && i !== 'id') {
									let itemEl = form.find('[name="' + i + '"]');
									if (itemEl.data('onload-datetimepicker')) {
										if (item[i] !== '' && item[i] !== null) {
											itemEl.datetimepicker('date', moment(item[i]));
										}
									} else {
										itemEl.val(item[i]);
									}
								}
							}
						}
					});

					el.trigger('change').trigger('select2:select');
				});
			}
		}
	});
};

export { loadFromSource };
